import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import TeamMembers from '@interness/web-core/src/components/modules/TeamMembers';
import { findMedia } from '@interness/web-core/src/components/_helpers';
export const query = graphql`
  query {
    headerImages: directusMediaCollection(name: {eq: "header"}) {
      name
      images: media {
        file {
          localFile {
            name
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 700, quality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
      <Img fluid={findMedia(props.data.headerImages.images, 'gruppe').childImageSharp.fluid} mdxType="Img" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Über uns`}</h1>
      <hr></hr>
      <TeamMembers mdxType="TeamMembers" />
      <hr></hr>
    </Wrapper>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      